
// Fonts
@import url(https://fonts.googleapis.com/css?family=Raleway:300,400,600);

// Variables
@import "variables";

// Bootstrap
@import "node_modules/bootstrap-sass/assets/stylesheets/bootstrap";

body {
    background: #edf1f5;
    color: #656D78;
}

.loader {
    font-size:0px;
    padding:0px;
    position: absolute;
    width: 100%;
    height: 100%;
    background: rgba(0,0,0,.25);
    z-index: 99;
    display: none;
}

#fountainG{
    position:relative;
    width:234px;
    height:28px;
    margin:auto;
    top: 50%;
}

.fountainG{
    position:absolute;
    top:0;
    background-color:rgb(0,0,0);
    width:28px;
    height:28px;
    animation-name:bounce_fountainG;
        -o-animation-name:bounce_fountainG;
        -ms-animation-name:bounce_fountainG;
        -webkit-animation-name:bounce_fountainG;
        -moz-animation-name:bounce_fountainG;
    animation-duration:1.5s;
        -o-animation-duration:1.5s;
        -ms-animation-duration:1.5s;
        -webkit-animation-duration:1.5s;
        -moz-animation-duration:1.5s;
    animation-iteration-count:infinite;
        -o-animation-iteration-count:infinite;
        -ms-animation-iteration-count:infinite;
        -webkit-animation-iteration-count:infinite;
        -moz-animation-iteration-count:infinite;
    animation-direction:normal;
        -o-animation-direction:normal;
        -ms-animation-direction:normal;
        -webkit-animation-direction:normal;
        -moz-animation-direction:normal;
    transform:scale(.3);
        -o-transform:scale(.3);
        -ms-transform:scale(.3);
        -webkit-transform:scale(.3);
        -moz-transform:scale(.3);
    border-radius:19px;
        -o-border-radius:19px;
        -ms-border-radius:19px;
        -webkit-border-radius:19px;
        -moz-border-radius:19px;
}

#fountainG_1{
    left:0;
    animation-delay:0.6s;
        -o-animation-delay:0.6s;
        -ms-animation-delay:0.6s;
        -webkit-animation-delay:0.6s;
        -moz-animation-delay:0.6s;
}

#fountainG_2{
    left:29px;
    animation-delay:0.75s;
        -o-animation-delay:0.75s;
        -ms-animation-delay:0.75s;
        -webkit-animation-delay:0.75s;
        -moz-animation-delay:0.75s;
}

#fountainG_3{
    left:58px;
    animation-delay:0.9s;
        -o-animation-delay:0.9s;
        -ms-animation-delay:0.9s;
        -webkit-animation-delay:0.9s;
        -moz-animation-delay:0.9s;
}

#fountainG_4{
    left:88px;
    animation-delay:1.05s;
        -o-animation-delay:1.05s;
        -ms-animation-delay:1.05s;
        -webkit-animation-delay:1.05s;
        -moz-animation-delay:1.05s;
}

#fountainG_5{
    left:117px;
    animation-delay:1.2s;
        -o-animation-delay:1.2s;
        -ms-animation-delay:1.2s;
        -webkit-animation-delay:1.2s;
        -moz-animation-delay:1.2s;
}

#fountainG_6{
    left:146px;
    animation-delay:1.35s;
        -o-animation-delay:1.35s;
        -ms-animation-delay:1.35s;
        -webkit-animation-delay:1.35s;
        -moz-animation-delay:1.35s;
}

#fountainG_7{
    left:175px;
    animation-delay:1.5s;
        -o-animation-delay:1.5s;
        -ms-animation-delay:1.5s;
        -webkit-animation-delay:1.5s;
        -moz-animation-delay:1.5s;
}

#fountainG_8{
    left:205px;
    animation-delay:1.64s;
        -o-animation-delay:1.64s;
        -ms-animation-delay:1.64s;
        -webkit-animation-delay:1.64s;
        -moz-animation-delay:1.64s;
}



@keyframes bounce_fountainG{
    0%{
    transform:scale(1);
        background-color:rgb(0,0,0);
    }

    100%{
    transform:scale(.3);
        background-color:rgb(255,255,255);
    }
}

@-o-keyframes bounce_fountainG{
    0%{
    -o-transform:scale(1);
        background-color:rgb(0,0,0);
    }

    100%{
    -o-transform:scale(.3);
        background-color:rgb(255,255,255);
    }
}

@-ms-keyframes bounce_fountainG{
    0%{
    -ms-transform:scale(1);
        background-color:rgb(0,0,0);
    }

    100%{
    -ms-transform:scale(.3);
        background-color:rgb(255,255,255);
    }
}

@-webkit-keyframes bounce_fountainG{
    0%{
    -webkit-transform:scale(1);
        background-color:rgb(0,0,0);
    }

    100%{
    -webkit-transform:scale(.3);
        background-color:rgb(255,255,255);
    }
}

@-moz-keyframes bounce_fountainG{
    0%{
    -moz-transform:scale(1);
        background-color:rgb(0,0,0);
    }

    100%{
    -moz-transform:scale(.3);
        background-color:rgb(255,255,255);
    }
}

.main-top {
    background-color: #FFFFFF;
    margin-bottom: 0 !important;
    padding: 20px 0;
    border-bottom: 1px solid #ccc;

    .fluid-header {
        padding-left: 50px;
    }
}

.fluid-body {
    padding: 50px 30px;
}

.title {
    font-size: 28px;
    margin: 0;
}

hr {
    padding: 0;
}

.panel-default {
    padding: 15px 20px;
}

.kpi {
    padding: 10px;
    min-height: 126px;

    .panel-body {
        padding: 5px;

        hr {
            margin: 5px;
        }
    }

    .kpi-indicator {
        font-size: 20px;
        font-weight: bold;
    }

    .kpi-percentage {
        font-size: 12px;
    }
}

.no-left-padding {
    padding-left: 0;
}

.form-submit-margin {
    margin-top: 25px;
}

.spinner {
  margin: 20px auto 0;
  width: 70px;
  text-align: center;
}

.spinner > div {
  width: 18px;
  height: 18px;
  background-color: #333;

  border-radius: 100%;
  display: inline-block;
  -webkit-animation: sk-bouncedelay 1.4s infinite ease-in-out both;
  animation: sk-bouncedelay 1.4s infinite ease-in-out both;
}

.spinner .bounce1 {
  -webkit-animation-delay: -0.32s;
  animation-delay: -0.32s;
}

.spinner .bounce2 {
  -webkit-animation-delay: -0.16s;
  animation-delay: -0.16s;
}

@-webkit-keyframes sk-bouncedelay {
  0%, 80%, 100% { -webkit-transform: scale(0) }
  40% { -webkit-transform: scale(1.0) }
}

@keyframes sk-bouncedelay {
  0%, 80%, 100% { 
    -webkit-transform: scale(0);
    transform: scale(0);
  } 40% { 
    -webkit-transform: scale(1.0);
    transform: scale(1.0);
  }
}

.dataset {
    display: none;
}

.hide-it {
    display: none;
}

.adjusted-form-group {
    width: 210px;
}

$main-color: blue;

.efeito-transicao {
    transition: ease 0.2s;
    -webkit-transition: ease 0.2s;
    -moz-transition: ease 0.2s;
}

.fix-padding {
  padding-left: 0 !important;
  padding-right: 0 !important;
}

.fix-margin-top {
  margin-top: -11px;
}

body {
    font-family: 'Source Sans Pro', sans-serif;
}

section.dashboard-resume {
    background: rgb(20,115,200);
    background: -moz-linear-gradient(top, rgba(20,115,200,1) 0%, rgba(44,145,236,1) 100%);
    background: -webkit-linear-gradient(top, rgba(20,115,200,1) 0%,rgba(44,145,236,1) 100%);
    background: linear-gradient(to bottom, rgba(20,115,200,1) 0%,rgba(44,145,236,1) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#1473c8', endColorstr='#2c91ec',GradientType=0 );
    padding: 40px 90px;

    h1 {
        margin-bottom: 0;
        margin-top: 50px;
        font-weight: 300;
        font-size: 26px;
        color: #FFFFFF;
    }
    h3 {
        margin-bottom: 25px;
        margin-top: 15px;
        font-size: 16px;
        color: #5FAEFE;
    }
    a.btn {
        padding: 10px 25px;
        background: none;
        text-transform: uppercase;
        border-radius: 40px;
        font-weight: 700;
        font-size: 12px;
        border: 2px solid #54A9F6;
        color: #d8e9fa;
        &:hover {
            background-color: #54A9F6;
            color: #FFFFFF;
        }
    }
}

.dashboard-content {
    padding: 60px 25px;

    .box-dashboard {
        -webkit-box-shadow: 0 0 3px 0 rgba(0,0,0,0.10);
        box-shadow: 0 0 3px 0 rgba(0,0,0,0.10);

        background-color: #FFFFFF;
        border-radius: 3px;
        margin-bottom: 35px;

        .box-title {
            border-bottom: 1px solid #E6E9ED;
            font-size: 18px;
            padding: 20px;
            color: $main-color;
        }
        .box-content {
            padding: 20px;
        }
    }

}

/*Dashboard Comunicacao Interna*/
html.dashboard, html.dashboard body {
    height: 100%;
}
// Cores

$cor-destaque:    #95ff06;
$cor-secundaria:  #ecd154;
$cor-verde:       #54ecc6;
$cor-rosa:        #e754ec;
$cor-laranja:     #ffb30f;
$cor-amarelo:     #f1ff0f;
$cor-erro:        red;
$cor-sucesso:     #95ff06;

html.dashboard body {
/* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#2c91ec+0,1731a2+40,004584+72,02bdfa+100 */
background: #2c91ec; /* Old browsers */
background: -moz-linear-gradient(-45deg, #02bdfa 0%, #004584 25%, #070073 72%, #2693f5 100%); /* FF3.6-15 */
background: -webkit-linear-gradient(-45deg, #02bdfa 0%, #004584 25%, #070073 72%, #2693f5 100%); /* Chrome10-25,Safari5.1-6 */
background: linear-gradient(135deg, #02bdfa 0%, #004584 25%, #070073 72%, #2693f5 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#2c91ec', endColorstr='#02bdfa',GradientType=1 ); /* IE6-9 fallback on horizontal gradient */
background-attachment: fixed;

    height: 100%;


// Margem bootstrap
    .col {
      padding-right: 5px !important;
      padding-left: 5px !important;
    }

    .bordered {
        border-right: 1px solid rgba(255,255,255,0.1);
    }
    .cor-branca      { color: white; }
    .cor-destaque    { color: $cor-destaque; }
    .cor-secundaria  { color: $cor-secundaria; }
    .cor-verde       { color: $cor-verde; }
    .cor-rosa        { color: $cor-rosa; }
    .cor-laranja     { color: $cor-laranja; }
    .cor-amarelo     { color: $cor-amarelo; }
    .cor-erro        { color: $cor-erro; }
    .cor-sucesso     { color: $cor-sucesso; }
}

// Estrutura
.container-dashboard {

    padding: 20px;

    @media (max-width: 620px) {
        padding: 20px;
    }

// Cards

    .card-container {
//      background: rgba(255,255,255,0.05);
      border-radius: 6px;
      padding: 15px 10px;
      overflow: hidden;
//      box-shadow: 0 4px 0 rgba(0,0,0,0.1);
      border: 1px solid rgba(white,0.2);
    }

    .card-dashboard {

        background: rgba(255,255,255,0.1);
        border-radius: 4px;
        box-shadow: 0 4px 0 rgba(0,0,0,0.1);
        overflow: hidden;
        margin-bottom: 15px;

        .box-card {
            padding: 25px 20px 30px;
            margin: 0;
            overflow: hidden;
        }

        .box-icon, .box-texto {
            display: inline-block;
            vertical-align: middle;

            span {
                display: block;
            }

        }

        .box-texto {
            color: white;
            line-height: 1.1;
        }

        @media (max-width: 1596px) and (min-width: 1200px) {

            .box-texto-full-mobile, .box-icon-full-mobile {
                width: 100%;
                text-align: center;
            }

        }

        @media (max-width: 575px) {

            .box-texto-full-mobile, .box-icon-full-mobile {
                width: 100%;
                text-align: center;
            }

        }

    }

    .spacer {
        margin-bottom: 10px;
    }

    .spacer-30 {
        margin-bottom: 30px;
    }

    .divisor {
        height: 1px;
        background: rgba(255,255,255,0.1);
        border: 0;
        display: block;
        margin: 15px 0;

        &.divisor-20 {
          margin: 20px 0;
        }

    }

    .titulo-dashboard {
        width: 100%;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        padding: 20px;
        margin: 0;
        font-size: 16px;
        font-weight: 600;
        color: white;
        text-transform: uppercase;
        box-shadow: 0 4px 0 rgba(0,0,0,0.05), 4px 0 0 $cor-destaque inset;
        background: rgba(255,255,255,0.1);

        &.titulo-destaque {
            background: darken($cor-destaque,12%);
        }

        &.titulo-destaque-erro {
            background: darken($cor-erro,12%);
            box-shadow: 0 4px 0 rgba(0,0,0,0.05), 4px 0 0 $cor-erro inset;
        }
    }

    // Texto
    .texto-sm {
        font-size: 20px;
    }

    .texto-md {
        font-size: 32px;
    }

    .texto-lg {
        font-size: 38px;
        @media (max-width: 1544px) and (min-width: 1200px) {
            font-size: 20px;
        }

        &.no-fix-mobile {

          @media (max-width: 1544px) and (min-width: 1200px) {
              font-size: 38px;
          }

        }
    }

    .texto-xl {
        font-size: 48px;
    }

    .texto-light {
        font-weight: 200;
    }

    .texto-bold {
        font-weight: 900;
    }

    small {
        font-size: 0.6em;
    }


    // Ícones

    .icon-xl {
        font-size: 128px;
    }

    .icon-lg {
        font-size: 90px;
    }

    .icon-md {
        font-size: 32px;
    }

    .icon-md-lg {
        font-size: 56px;
    }

    .fix-white-bg {
        position: relative;
        width: 95%;
        height: 95%;
        border-radius: 50%;
        background: white;
        display: block;
        left: 0;

    }

        // Tabela

        .btn-table {
            color: $cor-destaque;
        }

        .table-inverse {

            margin-bottom: 0;

            th, td {
                color: white;
            }

            th {
                border-color: rgba(255,255,255,0.1) !important;
                font-size: 17px;
                font-weight: 600;
            }

            td {
               font-size: 16px;
               border: 0 !important;
               padding-top: 10px !important;
               padding-bottom: 10px !important;
            }

            &.table-striped>tbody>tr    {
                background: rgba(255,255,255,0.05);
            }

            &.table-striped>tbody>tr:nth-of-type(odd)    {
                background: none;
            }
        }

// Formulário

    .form-control-inverse {

        background: none;
        color: white;
        border: 0;
        border-bottom: 1px solid rgba(255,255,255,0.5);
        box-shadow: none;
        border-radius: 0;
        font-size: 18px;
        padding: 10px;

            &:focus{
                color: white;
            }

            option {
                color: white !important;
                background: #013580 !important;
                border: 0 !important;
            }
    }

    .form-control-transparent {

        background: none;
        color: $cor-destaque !important;
        border: 2px solid rgba(255,255,255,0.2);
        box-shadow: none;
        text-align: center !important;
        border-radius: 6px;
        font-size: 22px;
        padding: 15px;
        height: 46px;

        &::placeholder {
            color: $cor-destaque !important;
        }

            &:focus{
                color: white;
                border: 2px solid $cor-destaque;
            }

            option {
                color: white !important;
                background: #013580 !important;
                border: 0 !important;
            }
    }

    label {
        color: white;
    }

    //botões

    .btn-transparent {
//      background: rgba(white,0.05);
      color: $cor-destaque;
      background: none;
      @extend .efeito-transicao;
      font-size: 14px;
      font-weight: 700;
      text-transform: uppercase;

      &:hover, &:focus {
        background: darken($cor-destaque,10%);
        color: white;
      }


    }

    .btn-menu{
        position: absolute;
        left: auto;
        right: 3.5%;
    }
    .btn-dashboard-menu{
        padding: 3px 6px 2px 8px;
    }
    .btn-dashboard{
         background-color: rgba(255, 255, 255, 0.1);
         color: #FFF;
         box-shadow: 0 2px 0 rgba(0, 0, 0, 0.05), 2px 0 0 #95ff06 inset;
          &:hover{
                color: #F2F2F2;
            }
            &:focus{
                color: #F2F2F2;
                box-shadow: 0 2px 0 rgba(0, 0, 0, 0.05), 2px 0 0 #95ff06 inset;
            }
    }

    .btn-form{
         background-color: darken($cor-destaque,12%);
         color: #FFF;
         @extend .efeito-transicao;

          &:hover{
                color: #F2F2F2;
                background-color: darken($cor-destaque,18%);
            }
            &:focus{
                color: #F2F2F2;
                background-color: darken($cor-destaque,12%);
            }
    }
}